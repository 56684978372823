@import "variables.css";
@import "font.css";
@import "components/index.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    font-family: 'Catchy Mager';
    font-feature-settings: "kern" 0, "calt" 0, "liga" 0, "clig" 0, "dlig" 0, "hlig" 0;
    letter-spacing: 0em;
}

h1 {
    font-size: 4rem;
    color: #545454;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    line-height: 4.2rem;
}

h2 {
    font-size: 2rem;
    color: #a6a6a6;

}

p {
    /* font-size: 1.5rem; */
}

.bg--primary {
    background: $primary;
}

.bg--secondary {
    background: $white;
}

.bg--dark {
    background: $bg-dark;
    color: $white;
}
